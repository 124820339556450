<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(created_time)="data">
            <div>{{ data.item.created_time | moment($formatDateNew) }}</div>
            <div class="time-color">
              {{ data.item.created_time | moment("HH:mm:ss") }}
            </div>
          </template>
          <template v-slot:cell(display_name)="data">
            <div>
              <span v-if="data.item.display_name">{{
                data.item.display_name
              }}</span>
              <span v-else>-</span>
            </div>
          </template>
          <template v-slot:cell(ip_address)="data">
            <div>
              <span v-if="data.item.ip_address">{{
                data.item.ip_address
              }}</span>
              <span v-else>-</span>
            </div>
          </template>
          <template v-slot:cell(target)="data">
            <div>
              <span v-if="data.item.target">{{ data.item.target }}</span>
              <span v-else>-</span>
            </div>
          </template>
          <template v-slot:cell(detail)="data">
            <b-button
              variant="link"
              class="px-1 py-0 link-btn"
              @click.prevent="
                $router.push(`/activitylog/detail/${data.item.id}`)
              "
            >
              <u>Detail</u>
            </b-button>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6" class="form-inline justify-content-start">
        <p class="mb-0 p-gray">
          Showing
          <span v-if="items.length === 0" id="from-row">0</span>
          <span v-else id="from-row">{{
            (filter.page - 1) * filter.take + 1
          }}</span>
          to
          <span id="to-row">
            {{
              filter.take * filter.page > rows
                ? rows
                : filter.take * filter.page
            }}</span
          >
          of <span id="total-row">{{ rows }}</span> entries
        </p>
      </b-col>
      <b-col cols="12" md="6" class="form-inline justify-content-end">
        <b-pagination
          v-model="filter.page"
          :total-rows="rows"
          :per-page="filter.take"
          @change="pagination"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          class="m-md-0"
        ></b-pagination>
        <b-form-select
          class="ml-2"
          v-model="filter.take"
          @change="handleChangeTake"
          :options="pageOptions"
        ></b-form-select>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowSidebar: false,
      toShow: this.showingTo,
      show1: this.showing,
    };
  },
  props: {
    fields: {
      required: true,
      type: Array,
    },
    rows: {
      required: true,
      type: Number,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    showingTo: {
      required: true,
      type: Number,
    },
    filter: {
      required: true,
      type: Object,
    },
    showing: {
      required: true,
      type: Number,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
  },
  methods: {
    handleChangeTake(value) {
      this.filter.page = 1;
      this.show1 = 1;
      this.filter.take = value;
      this.toShow = value;
      this.filter.start_date = null;
      this.filter.end_date = null;
      this.filter.activity_type = [];
      this.$emit("getPageOption", this.filter);
    },
    pagination(value) {
      this.filter.page = value;
      this.filter.start_date = null;
      this.filter.end_date = null;
      if (value === 1) {
        this.show1 = this.filter.page;
        this.toShow = this.filter.take;
      } else {
        this.show1 = value * this.filter.take - this.filter.take;
        this.toShow = this.show1 + this.filter.take;
      }
      this.$emit("page", this.filter);
    },
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-underline {
  text-decoration: underline;
}
.link-btn {
  color: var(--primary-color);
}
</style>
